.legende {
  padding: 15px;
  background: #eee;
  border-radius: 6px;
  margin-top: 30px;
  font-size: 12px;
  p {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 30px;
    margin-top: 15px;
    padding-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0 30px 0 30px;
    float: left;
    padding: 30px;
  }
}

[class^="class-field"],
[class^="no_work-field"],
[class^="probation_detail_"] {
  display: none;
}

[class^="probation_detail_"] {
  font-style: italic;
  max-width: 220px;
}

table.default-table div[class^="no_work-field"] input[type="text"] {
  text-align: left;
  width: 200px;
}

#report-form span.amount {
  white-space: nowrap;
}

#table-print-report tbody td {
  white-space: nowrap;
}

#table-print-report tfoot td {
  text-transform: uppercase;
}

#report-form-table {
  display: none;
}
