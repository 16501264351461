.center {
  position: fixed;
  top: 50%;
  right: 0px;
  width: 115px;
  height: 0px;
  text-align:right;
  z-index:9999;
  margin-top:-15px;
}

.center div {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  display: block;
  background: $unifor-blue;
  text-align:center;
  height: 50px;
  width: 125px;
  padding: 18px 16px;
  color: #fff;
  font-family: $font-titles;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}

.center div:hover {
  background: $unifor-red;
}
