.navbar-default .navbar-nav > li > a {
  color: $dark-grey;
  font-weight: 700;
  font-family: $font-titles;

  &:hover, &:active, &:focus {
   color: $unifor-red;
  }
}


a.navbar-brand img {
  max-width: 200px;
}

.navbar-default {
  height: 100px;
  background-color: white;
}

.navbar-right {
  @media screen and (min-width: $screen-sm-min) {
    padding-top: 25px;
  }

  li {
    text-transform: uppercase;
  }
}

.navbar-default .navbar-toggle {
  @media screen and (max-width: $screen-sm-min) {
    margin-top: 30px;
  }
}

.navbar-default .usermenu {
  padding-top: 0;
  margin-top: -10px;
  margin-right: 15px;
  position: absolute;
  right: 0;
  z-index: 1000;

  @media screen and (max-width: $grid-float-breakpoint) {
    position: relative;
    margin-top: 15px;
  }

  & > li > a {
    color: $grey;
    text-transform: none;
    padding-bottom: 5px;
  }
}

.usermenu .dropdown-menu li a {
  text-transform: none;

  &:hover {
    background-color: white;
  }

}

.usermenu .dropdown-menu {
  border: none;
  box-shadow: none;
  background-color: $light-grey;

  .open a:hover {
    background-color: white;
  }

  li a {
    text-transform: none;
  }
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: white;
}

.navbar-collapse {
  @media screen and (max-width: $grid-float-breakpoint) {
    background-color: white;
    margin-top: 27px;
  }
}

.navbar-default .navbar-nav a.active {
  color: $unifor-red;
}
