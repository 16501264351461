.Inputfields {
  list-style-type: none;
}

.form-group.Inputfield.InputfieldColumnWidthFirst {
  width: 33%;
  display: inline-block;
  padding: 10px;
  vertical-align: top;
}

#wrap_form_employer_submit,
#wrap_form_employee_submit,
#wrap_form_cba_submit,
#wrap_form_class_submit{
  width: 100%;
  display: block;
  text-align: center;
}

table.ui-datepicker-calendar,
.ui-datepicker,
#ui-datepicker-div {
  background-color: white;
}

.ui-datepicker {
  border: 2px solid #eae9e8;
}

.ui-datepicker-header {
  display: block;
  text-align: center;
}

.ui-datepicker-title {
  margin: 20px 0;
}

.ui-datepicker-month {
  margin-right: 20px;
}

.ui-datepicker-prev {
  position: absolute;
  left: 10px;
}

.ui-datepicker-next {
  position: absolute;
  right: 10px;
}

table.ui-datepicker-calendar tr:first-child th:first-child {
    min-width: 0px;
}

#ui-datepicker-div {
  background-color: white;
  background-image: none;

  .ui-widget-header {
    background: $base-blue;
    border: none;
  }
}

.centered {
  text-align: center;
  display: inherit;
}

#cla_name {
  width: 40%;
  margin: 0 auto;
}

.ui-datepicker-header .ui-datepicker-month,
.ui-datepicker-header .ui-datepicker-year {
  color: black;
  font-weight: 200;
}
