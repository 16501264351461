.default-table {
  width: 100%;
  background: #E6E6E6;
  border-radius: 10px;
  th {
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid $grey;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  tr {
    text-align: center;
    border-bottom: 5px solid $dark-grey;
  }
}

.print-report {
  table-layout: fixed;
  font-size: 10px;
  margin-top:20px;
  th{
    font-size: 10px;
  }
}

.table-employees {
  width: 100%;
  background-color: #E6E6E6;
  border: solid 1px blue;
}

.default-table th {
  background-color: #E6E6E6;
}

.table-new-report {
  width: 100%;
  background-color: #777;
}
.table-new-report tr {
  border-bottom: 1px solid black;
}

.table-headers {
  width: 100%;
  background-color: #777;
}

.infos-report {
  margin-bottom: 30px;
  margin-top: 40px;
}

table.default-table.print-report thead tr th {
  font-size: 10px;
  padding: 10px 5px;
}

.print-report tbody tr td {
  padding: 5px;
}

.print-report tfoot tr td {
  color: #444;
  font-weight: bold;
}

.print-report tfoot tr td.total-hours {
  background: #888;
}

.print-report tfoot tr td.total-gains {
  background: #aaa;
}

.print-report tfoot tr td.total-regular-dues {
  background: #ccc;
}

.print-report tfoot tr td.total-other-dues {
  background: #ddd;
}

.print-report tfoot tr td.total-dues {
  background: rgba(19, 114, 185, 0.5);
}
