#union-login {
    display:inline-block;
    width:200px;
}

.centered-login {
    display: block;
    margin: 30px auto;
    padding: 30px;
    width: 480px;
}

.centered-login img {
    max-width: 380px;
    display: block;
    margin: 0 auto;
}

.centered-login a p {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px, 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 7px 15px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
    background-color: #1372b9;
    color: white;
    margin: 10px 0;
}

.centered-login a:hover p {
    background-color: #2587d0;
}

#union-login {
    border-right: 1px solid grey;
    display: inline-block;
    height: 153px;
    width: 48%;
    padding-right: 15px;
}

#dealer-login {
    display: inline-block;
    width: 48%;
    height: 153px;
}

.login-secondary-links {
    display: inline-block;
    margin: 10px 10px;
    color: #696969;
}

#fuLoginForm .ui-widget-content {
    background: none !important;
    border: none !important;
}

a {
    -o-transition: color .5s;
    -ms-transition: color .5s;
    -moz-transition: color .5s;
    -webkit-transition: color .5s;
    transition: color .5s;
}

input[type="submit"] {
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
}

.buttongroup {
    display: block;
    margin: 10px auto;
    text-align: center;
}

h1, .h1 {
    color: #696969;
    font-size: 30px;
    line-height: 1.3em;
    font-weight: bold;
    margin-bottom: 25px;
    font-family: "Montserrat",sans-serif;
}

h2, .h2 {
    color: #696969;
    font-size: 25px;
    line-height: 1.3em;
    font-weight: bold;
    margin-bottom: 18px;
    font-family: "Montserrat",sans-serif;
}

h3, .h3 {
    color: #696969;
    font-size: 20px;
    line-height: 1.3em;
    font-weight: bold;
    margin-bottom: 14px;
    font-family: "Montserrat",sans-serif;
}

label {
    color: #696969;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.3em;
    font-family: "Montserrat",sans-serif;
    font-weight: normal;
}

table.default-table {
    background: #fbfbfb;
    border-collapse: separate;
    border: solid #1372b9 2px;
    border-radius: 6px;
    -moz-border-radius: 6px;
    color: #6c6c6c;
}

.default-table th {
    border-bottom: 2px white;
}

table.default-table tr:first-child th {
    background: #fff none repeat scroll 0 0;
    border-radius: 0;
    color: #444444;
    font-size: 14px;
    line-height: 1.3em;
    padding: 10px;
    font-family: "Montserrat",sans-serif;
}

table.default-table tr {
    border-bottom: solid #1372b9 0px;
}

table tr th,
table tr td {
    border-right: 2px solid #eae9e8;
    border-bottom: 0px solid #1372b9;
    padding: 20px;
}

table tr:first-child th:first-child {
    border-top-left-radius: 6px;
    min-width: 60px;
}

table tr:first-child th:last-child {
    border-top-right-radius: 6px;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}

table.sortable th:not(.sorttable_sorted):not(.sorttable_sorted_reverse):not(.sorttable_nosort):first-child:after {
    content: " \25B4\25BE"
}

table.sortable tbody tr:nth-child(2n) td {
    background: #fbfbfb;
}

table.sortable tbody tr:nth-child(2n+1) td {
    background: #fff;
}

table.sorttable_sortrevind {
    color:#1372b9;
}

table.sorttable_sorted_reverse {
    color:#1372b9;
}

table.default-table .fa-caret-down {
    display:none !important;
}

.td-syndique {
    text-align: left;
    vertical-align: top;
}

.span-syndique {
    font-weight: bold;
    font-family: "Montserrat",sans-serif;
    color: #6c6c6c;
}

table.default-table label {
    font-size: 13px;
    font-weight: normal;
}

table.default-table input[type="text"] {
    margin-bottom: 12px;
    max-width: 86px;
    text-align: center;
}

.span-checkbox {
    display: block;
    font-size: 12px;
    padding-top: 15px;
}

table.default-table a {
    color: #6c6c6c;
    text-decoration: none;
    font-family: "Montserrat",sans-serif;
}

table.default-table a:hover {
    color: #1372b9;
}

table.default-table input[type="checkbox"] {
    margin-right: 10px;
}

#report-form table.default-table tr td {
    border-bottom: 2px solid #1372b9;
    vertical-align: top;
}

#report-form table.default-table tr:last-child td {
    border-bottom: 0px solid;
}

#report-form table.default-table tr td:last-child {
    border-right: 0px solid;
}

#report-form table.default-table tr th:last-child {
    border-right: 0px solid;
}

.settings {
    display: block;
}

.settings h3, p {
    display: inline-block;
    font-weight: normal;
    margin-top: 0;
}
