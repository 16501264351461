#fhSubmit1,
#btn-new-cba,
#btn-new-employer,
#btn-clone,
#btn-new-emp,
#btn-clone-employee,
#btn-new-report-validate1,
#btn-new-report-draft,
#btn-new-report-send,
.InputfieldContent .btn,
#add_class,
.btn-submit {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  padding:$padding-base-vertical, $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $line-height-base;
  border-radius: $btn-border-radius-base;
  @include user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus();
    }
  }

  &:hover,
  &:focus,
  &.focus {
    background-color: $hover-blue;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include box-shadow(none);
  }

  a {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }

  //Make buttons large
  padding: 7px 15px;
  font-size: $font-size-large;
  line-height: $line-height-large;
  border-radius: $btn-border-radius-large;

  background-color: $base-blue;
  color: white;
  margin: 10px 0;
}

#btn-clone,
#btn-clone-employee,
#btn-new-report-draft,
#btn-new-report-send,
#add_class,
#logout,
.btn-alt {
  background-color: white;
  color: $text-grey;
  border: 2px solid $base-blue;
  margin-left: 10px;

  &:hover,
  &:focus,
  &.focus {
    background-color: white;
    color: $hover-blue;
    border: 2px solid $hover-blue;
  }
}
