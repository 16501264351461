$default-font: "Arial", sans-serif;
$font-titles: "Montserrat", sans-serif;

$base-blue: #1372b9;
$hover-blue: #2587d0;

$unifor-red: #c31a1a;
$unifor-blue: #005eb8;

$light-grey: #E2E2E2;
$grey: #777;
$dark-grey: #666666;
$text-grey: #696969;
