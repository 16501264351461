.homewrap {
  margin-bottom: 60px;
}

.actionbox {
  min-height: 250px;
  border: 0;
  margin-bottom: 40px;
  margin-top: 40px;
  box-shadow: 0px 0px 5px $grey;
  padding-bottom: 20px;
  cursor: pointer;
}

.actionbox hr {
  border: 1px solid $grey;
}

.hidden {
  display: none;
}

.logintitle {
  text-align: center;
}

.loginbox {
  text-align: center;
  box-shadow: 0px 0px 5px $grey;
  padding: 60px 0;
  margin-top: 40px;
}

ul.Inputfields {
  padding-left: 0;
}

li.Inputfield {
  margin: 10px 0;
}

.actionbox a h3 {
  font-weight: normal;
  margin-top: 0;
  padding-top: 20px;

  i {
    color: $base-blue;
    font-size: 2em;
    padding: 0 15px 5px 5px;
    vertical-align: middle;
  }
}
