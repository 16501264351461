@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/jquery-ui/themes/ui-lightness/jquery-ui.min";

@import "variables";

@import "boxes";
@import "ben";
@import "buttons";
@import "forms";
@import "navigation";
@import "report";
@import "sticky";
@import "tables";

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

#table-print-report tbody td.wrap {
  white-space: normal;
  width: 320px;
}

* {
  box-sizing: border-box;
}

#footer {
  margin-top: 60px;
  font-size: .8em;
  text-align: center;
}

.btn-help {
  box-shadow: 0px 0px 5px $grey;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

table.ui-datepicker-calendar tr:first-child,
table.ui-datepicker-calendar th:first-child {
  min-width: 20px;
}

#exclamation-report {
  font-size: 1em;
  padding-right: 10px;
}
